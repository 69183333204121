/* eslint-disable max-classes-per-file */
import { type QueryFilters as TanstackQueryFilters } from '@tanstack/react-query';

import { type AssetsViewParams } from '../types';

const serializeForQueryKey = ( { projectID, folderID, filters, sorting }: AssetsViewParams ) => ( {
	projectID, folderID, ...filters?.toJson(), ...sorting?.toJson()
} );

class QueryKeys {
	static forAssetList( params: AssetsViewParams ) {
		return [ 'assets', 'list', serializeForQueryKey( params ) ];
	}

	static forFolderList( params: AssetsViewParams ) {
		return [ 'folders', 'list', serializeForQueryKey( params ) ];
	}

	static forItemsCount( params: AssetsViewParams ) {
		return [ 'itemsCount', serializeForQueryKey( params ) ];
	}

	static forFolderTree( projectID: number ) {
		return [ 'folderTree', projectID ];
	}
}

class QueryFilters {
	static forAssetAndFolderLists( {
		projectID, folderID, only, queryType = 'all'
	}: {
		projectID: number,
		folderID?: number | null,
		only?: 'assets' | 'folders',
		queryType?: TanstackQueryFilters[ 'type' ]
	} ): TanstackQueryFilters {
		return {
			type: queryType,
			predicate: ( { queryKey: key } ) => (
				( only
					? key[ 0 ] === only
					: ( key[ 0 ] === 'assets' || key[ 0 ] === 'folders' )
				)
				&& key[ 1 ] === 'list'
				&& key[ 2 ] instanceof Object
				&& 'projectID' in key[ 2 ]
				&& 'folderID' in key[ 2 ]
				&& key[ 2 ].projectID === projectID
				&& ( folderID === undefined || key[ 2 ].folderID === folderID )
			)
		};
	}
}

export { QueryKeys, QueryFilters };
