import { sharedTracker } from '../Tracker';
import events from '../events';

export const trackAddOnBought = ( { addOnType, includedAmount, plan, totalAmount, billingPeriod } ) => sharedTracker
	.trackAddOnAction( {
		action: events.addOns.ADD_ON_BOUGHT,
		add_on_type: addOnType,
		included_amount: includedAmount,
		plan,
		total_amount: totalAmount,
		billing_period: billingPeriod
	} );
