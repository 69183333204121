import PropTypes from 'prop-types';
import classNames from 'classnames';
import { stopPropagation } from '../../new_arch/lib/eventUtils';

import './FloatingBox.scss';
import useFloatingBoxPosition from './useFloatingBoxPosition';

const FloatingBox = ( {
	children, showArrow, className, style, automaticPositioning
} ) => {
	const { ref, positionStyle } = useFloatingBoxPosition();

	return (
		<div
			ref={ref}
			style={{
				...style,
				...( automaticPositioning ? positionStyle : {} )
			}}
			className={classNames( 'FloatingBox bottom hide', className, { 'show-arrow': showArrow } )}
			onClick={stopPropagation}
		>
			{children}
		</div>
	);
};

FloatingBox.propTypes = {
	children: PropTypes.node.isRequired,
	showArrow: PropTypes.bool,
	className: PropTypes.string,
	automaticPositioning: PropTypes.bool,
	style: PropTypes.any
};

FloatingBox.defaultProps = {
	showArrow: true,
	className: undefined,
	automaticPositioning: false,
	style: {}
};

export default FloatingBox;
