import { sharedTracker } from '../Tracker';
import events from '../events';

export const trackNavigatedToPricingModal = () => sharedTracker
	.trackNavigationAction( {
		action: events.navigation.NAVIGATED_TO_PRICING_MODAL
	} );

export const trackNavigatedToCheckoutModal = () => sharedTracker
	.trackNavigationAction( {
		action: events.navigation.NAVIGATED_TO_CHECKOUT_MODAL
	} );

export const trackNavigatedToMyProfile = () => sharedTracker
	.trackNavigationAction( {
		action: events.navigation.NAVIGATED_TO_MY_PROFILE
	} );

export const trackNavigatedToWorkspaceSettings = () => sharedTracker
	.trackNavigationAction( {
		action: events.navigation.NAVIGATED_TO_WORKSPACE_SETTINGS
	} );

export const trackNavigatedToWorkspaceProjects = () => sharedTracker
	.trackNavigationAction( {
		action: events.navigation.NAVIGATED_TO_WORKSPACE_PROJECTS
	} );

export const trackNavigatedToWorkspaceUsers = () => sharedTracker
	.trackNavigationAction( {
		action: events.navigation.NAVIGATED_TO_WORKSPACE_USERS
	} );

export const trackNavigatedToWorkspaceBilling = () => sharedTracker
	.trackNavigationAction( {
		action: events.navigation.NAVIGATED_TO_WORKSPACE_BILLING
	} );

export const trackNavigatedToProjectsView = () => sharedTracker
	.trackNavigationAction( {
		action: events.navigation.NAVIGATED_TO_PROJECTS_VIEW
	} );

export const trackNavigatedToCreateProjectModal = () => sharedTracker
	.trackNavigationAction( {
		action: events.navigation.NAVIGATED_TO_CREATE_PROJECT_MODAL
	} );

export const trackNavigatedToProject = ( { projectID, projectName } ) => sharedTracker
	.trackNavigationAction( {
		action: events.navigation.NAVIGATED_TO_PROJECT,
		project_id: projectID,
		project_name: projectName
	} );

export const trackNavigatedToFolder = ( { folderID, folderName, isNested, totalAssets } ) => sharedTracker
	.trackNavigationAction( {
		action: events.navigation.NAVIGATED_TO_FOLDER,
		folder_id: folderID,
		folder_name: folderName,
		is_nested: isNested,
		total_assets: totalAssets
	} );

export const trackNavigatedToFolderFromTree = ( { folderID } ) => sharedTracker
	.trackNavigationAction( {
		action: events.navigation.NAVIGATED_TO_FOLDER_FROM_TREE,
		folder_id: folderID
	} );

export const trackNavigatedToProjectSettings = () => sharedTracker
	.trackNavigationAction( {
		action: events.navigation.NAVIGATED_TO_PROJECT_SETTINGS
	} );

export const trackNavigatedToProjectInviteModal = () => sharedTracker
	.trackNavigationAction( {
		action: events.navigation.NAVIGATED_TO_PROJECT_INVITE_MODAL
	} );

export const trackNavigatedToAsset = asset => sharedTracker
	.trackNavigationAction( {
		action: events.navigation.NAVIGATED_TO_ASSET,
		asset_id: asset.id,
		asset_name: asset.name,
		asset_type: asset.isImage ? 'Image' : 'Document'
	} );

export const trackNavigatedToNotificationsPanel = () => sharedTracker
	.trackNavigationAction( {
		action: events.navigation.NAVIGATED_TO_NOTIFICATIONS_PANEL
	} );

export const trackNavigatedToTasksPanel = () => sharedTracker
	.trackNavigationAction( {
		action: events.navigation.NAVIGATED_TO_TASKS_PANEL
	} );

export const trackNavigatedToActivityLog = () => sharedTracker
	.trackNavigationAction( {
		action: events.navigation.NAVIGATED_TO_ACTIVITY_LOG
	} );

export const trackOpenedFolderTreePanel = () => sharedTracker
	.trackNavigationAction( {
		action: events.navigation.OPENED_FOLDER_TREE_PANEL
	} );
