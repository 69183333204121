import classNames from 'classnames';
import { forwardRef, type ForwardedRef, type RefObject } from 'react';

import './Button.scss';

/*
Note about the weird 'ref' usage:

Ideally we would just use forwardRef for this component
and export that, like always, without the need of an extra
buttonRef prop and exporting a version without forwardRef.

The problem is that this makes lots of Enzyme tests using
shallow rendering fail, because the Button component becomes
hidden by the wrapper applied by forwardRef.

However we still need to export a version with forwardRef
since this component is used as the target of a Mantine menu
and it only works correctly when using forwardRef.
*/

export const types = [ 'filled', 'text', 'round', 'link', 'ghost' ] as const;
export const colors = [ 'primary', 'secondary', 'danger' ] as const;
const sizes = [ 'regular', 'small', 'large', 'tall' ] as const;

type ButtonProps = {
	children: React.ReactNode;
	type?: typeof types[number];
	size?: typeof sizes[number];
	disabled?: boolean;
	onClick?: ( event: React.MouseEvent<HTMLButtonElement> ) => void;
	buttonType?: 'button' | 'submit' | 'reset';
	color?: typeof colors[number];
	className?: string;
	rightIcon?: React.ReactNode;
	buttonRef?: RefObject<HTMLButtonElement> | ForwardedRef<HTMLButtonElement>;
	testId?: string;
}

const Button = ( {
	children,
	onClick,
	className,
	type = 'filled',
	size = 'regular',
	disabled = false,
	buttonType = 'submit',
	color = 'primary',
	rightIcon,
	buttonRef,
	testId = 'button'
}: ButtonProps ) => {
	const buttonClassNames = classNames(
		'Button',
		type,
		size,
		color,
		className,
		{ disabled }
	);

	return (
		<button
			ref={buttonRef}
			className={buttonClassNames}
			disabled={disabled}
			onClick={onClick}
			type={buttonType}
			data-testid={testId}
		>
			{rightIcon
				? (
					<div className="children-and-icon-container">
						{children}
						{rightIcon}
					</div>
				)
				: children}
		</button>
	);
};

const RefForwardedButton = forwardRef<HTMLButtonElement, ButtonProps>(
	( props, ref ) => <Button {...props} buttonRef={ref} />
);

export default Button;
export { RefForwardedButton };
