import Asset from '../asset';
import LabelFilter from './labelFilter';
import StateFilter from './stateFilter';
import { FilterJSON } from './types';

export default class FilterOption {
	id!: string;
	isPassedBy!: ( asset: Asset ) => boolean;
	toJson!: () => FilterJSON;

	static fromJson( json: FilterJSON ): FilterOption | null {
		if ( 'label' in json ) {
			return LabelFilter.fromJson( json );
		}

		return StateFilter.fromJson( json );
	}
}
