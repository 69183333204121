import { throttle } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';

const BOTTOM_BAR_HEIGHT = 61;

const useFloatingBoxPosition = () => {
	const ref = useRef<HTMLDivElement>();
	const [ positionStyle, setPositionStyle ] = useState( { visibility: 'hidden', left: 0, top: 0 } );

	const updatePosition = useCallback( throttle(
		() => {
			if ( !ref.current ) { return; }
			const parentTop = ref.current.parentElement?.getBoundingClientRect()?.top || 0;
			const { height } = ref.current.getBoundingClientRect();
			const { innerHeight } = window;

			const newTop = parentTop + height + BOTTOM_BAR_HEIGHT > innerHeight
				? -height
				: 0;

			setPositionStyle( { visibility: 'visible', left: 0, top: newTop } );
		}
	), [ setPositionStyle ] );

	useEffect( () => { updatePosition(); }, [ updatePosition ] );

	return { ref, positionStyle };
};

export default useFloatingBoxPosition;
