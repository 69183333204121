import PropTypes from 'prop-types';
import WorkspaceSideBarItem from '../WorkspaceSideBarItem/WorkspaceSideBarItem';
import Workspace from '../../entities/workspace';
import CreateWorkspaceButton from '../CreateWorkspaceButton/CreateWorkspaceButton';

const WorkspacesSection = ( {
	title, className, workspaces, selectedWorkspaceID,
	onWorkspaceClick, currentUserID, onCreateWorkspaceClick,
	showCreateWorkspaceButton
} ) => (
	<div className={className}>
		<h4>{title}</h4>
		<div className="workspace-items">
			{showCreateWorkspaceButton && <CreateWorkspaceButton onClick={onCreateWorkspaceClick} />}
			{workspaces.map( workspace => (
				<WorkspaceSideBarItem
					key={workspace.id}
					workspace={workspace}
					selected={selectedWorkspaceID === workspace.id}
					onClick={onWorkspaceClick( workspace.id )}
					currentUserID={currentUserID}
				/>
			) )}
		</div>
	</div>
);

WorkspacesSection.propTypes = {
	title: PropTypes.string,
	className: PropTypes.string,
	workspaces: PropTypes.instanceOf( Workspace ),
	selectedWorkspaceID: PropTypes.number,
	onWorkspaceClick: PropTypes.func,
	currentUserID: PropTypes.number.isRequired,
	showCreateWorkspaceButton: PropTypes.bool,
	onCreateWorkspaceClick: PropTypes.func
};

WorkspacesSection.defaultProps = {
	title: undefined,
	className: undefined,
	workspaces: [],
	selectedWorkspaceID: undefined,
	onWorkspaceClick: () => { },
	showCreateWorkspaceButton: false,
	onCreateWorkspaceClick: () => {}
};

export default WorkspacesSection;
