import { useQuery } from '@tanstack/react-query';
import User from '../../../models/user/user';
import useAuthSystem from '../system/useAuthSystem';
import { CURRENT_USER_KEY } from './keys';
import newRelicAgent from '../../../../newRelic';

const useFetchCurrentUserQuery = ( { enabled } : { enabled: boolean } = { enabled: true } ) => {
	const system = useAuthSystem();
	const twentyFourHours = 1000 * 60 * 60 * 24;

	const query = useQuery(
		CURRENT_USER_KEY,
		system.fetchCurrentUser,
		{
			enabled: system.isAuthenticated && enabled,
			select: User.fromJson,
			staleTime: twentyFourHours,
			retry: false
		}
	);

	const currentUserID = query.data?.id;
	if ( currentUserID ) {
		newRelicAgent?.setUserId( currentUserID.toString() );
	}

	return query;
};

export default useFetchCurrentUserQuery;
