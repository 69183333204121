import { CURRENT_USER_URL, SESSIONS_URL, USERS_URL, CREATE_GUEST_SESSION_URL } from '../../../../config/urls';
import { isUnprocessableCredentialsError, isUserNotFoundError } from '../../../../errors/user';
import Api from '../../../../services/Api';
import { trackUserLoggedIn, trackUserLoggedOut, trackUserSignedUp } from '../../../../services/tracker/trackers/authentication';
import { trackCredentialsPromptCompleted } from '../../../../services/tracker/trackers/nac';
import ResponseError from '../../../errors/ResponseError';
import { UserJson } from '../../../models/user/user';
import ReduxTanStackSync from '../../../services/ReduxTanStackSync';
import AuthenticationStore from '../../../stores/AuthenticationStore';
import { ExternalProviderAuthParams } from '../CreateAccount/types';
import UnprocessableCredentialsError from '../errors/UnprocessableCredentialsError';
import AuthProvider from '../models/authProviders';

export type SignInWithEmailParams = {
	email: string;
	password: string;
	accessCode?: string;
}

export type SignInParams = SignInWithEmailParams | ExternalProviderAuthParams;

export type CreateAccountWithEmailParams = {
	name: string;
	email: string;
	password: string;
	accessCode?: string;
}

type SessionsEndpointParamsWithEmail = {
	provider: AuthProvider.Email;
	email: string;
	password: string;
	access_code?: string;
}

type SessionsEndpointParamsWithExternalProvider = {
	provider: Exclude<AuthProvider, AuthProvider.Email>;
	id_token: string;
}

type SessionsEndpointParams = SessionsEndpointParamsWithEmail | SessionsEndpointParamsWithExternalProvider;

type SignUpEndpointParamsWithEmail = {
	provider: AuthProvider.Email;
	user: { name: string; email: string; password: string };
	access_code: string;
}

type SignUpEndpointParamsWithExternalProvider = {
	provider: Exclude<AuthProvider, AuthProvider.Email>;
	user: { id_token: string };
}

type SignUpEndpointParams = ( SignUpEndpointParamsWithEmail | SignUpEndpointParamsWithExternalProvider ) & {
	invite_hash?: string;
	skip_workspace_creation?: boolean;
};

type createGuestSessionParams = {
	guestHash: string;
	email: string;
	name: string;
	role: string;
}

type AuthSystemParams = { api: Api, authenticationStore: AuthenticationStore, sync: ReduxTanStackSync };

export default class AuthSystem {
	private authenticationStore: AuthenticationStore;
	private api: Api;
	private sync: ReduxTanStackSync;

	constructor( { api, authenticationStore, sync }: AuthSystemParams ) {
		this.api = api;
		this.authenticationStore = authenticationStore;
		this.sync = sync;
	}

	get isAuthenticated() {
		return this.authenticationStore.isAuthenticated;
	}

	get isGuest() {
		return this.authenticationStore.isGuest;
	}

	signIn = ( params: SignInParams ) => {
		if ( 'email' in params ) return this.signInWithEmail( params );
		return this.signInWithExternalProvider( params );
	}

	createAccount = (
		params : CreateAccountWithEmailParams | ExternalProviderAuthParams,
		inviteHash?: string,
		isGuestSignup?: boolean
	) => {
		if ( 'email' in params ) return this.createAccountWithEmail( params, inviteHash, isGuestSignup );
		return this.createAccountWithExtProvider( params, inviteHash, isGuestSignup );
	}

	logout = () => this.api.delete( SESSIONS_URL ).then( this.handleLogoutSuccess );

	// TODO: Move to users system
	fetchCurrentUser = async () => {
		const { response: userJson } = await this.api.get( CURRENT_USER_URL ) as unknown as { response: UserJson };
		this.sync.currentUser( userJson );
		return userJson as UserJson;
	};

	// TODO: Move to users system
	updateCurrentUserRole = async ( role: string, userId: number ) => {
		const { response: userJson } = await this.api.put(
			`${USERS_URL}/${userId}/roles`, { role }
		) as unknown as { response: UserJson };

		return userJson as UserJson;
	}

	createGuestSession = async ( { guestHash, email, name, role }: createGuestSessionParams ) => {
		await this.api.post(
			CREATE_GUEST_SESSION_URL,
			{ email, name, guest_hash: guestHash, role }
		);

		trackCredentialsPromptCompleted( { name, email, role } );

		this.setAuthentication();
		this.setGuest();
	}

	private signInWithEmail( { email, password, accessCode }: SignInWithEmailParams ) {
		return this.sessionsRequest( { provider: AuthProvider.Email, email, password, access_code: accessCode } );
	}

	private async signInWithExternalProvider(
		{ provider, idToken }: ExternalProviderAuthParams, signUpIfUserNotFound = true
	) : Promise<Response> {
		try {
			return await this.sessionsRequest( { provider, id_token: idToken } );
		} catch ( error ) {
			if ( error instanceof ResponseError && isUserNotFoundError( error ) && signUpIfUserNotFound ) {
				return this.createAccountAndRetrySignIn( { provider, idToken } );
			}
			throw error;
		}
	}

	private async createAccountAndRetrySignIn( { provider, idToken }: ExternalProviderAuthParams ) {
		await this.createAccountWithExtProvider( { provider, idToken } );
		return this.signInWithExternalProvider( { provider, idToken }, false );
	}

	private async sessionsRequest( params: SessionsEndpointParams ) {
		try {
			const response = await this.api.post( SESSIONS_URL, params );

			trackUserLoggedIn();
			this.setAuthentication();
			return response;
		} catch ( error: unknown ) {
			if ( error instanceof ResponseError && isUnprocessableCredentialsError( error ) ) {
				this.throwUnprocessableCredentialsError( error );
			}
			throw error;
		}
	}

	private async createAccountWithEmail(
		{ name, email, password, accessCode = '' } : CreateAccountWithEmailParams,
		inviteHash?: string,
		isGuestSignup?: boolean
	) {
		return this.signUpRequestWithTracking(
			{
				provider: AuthProvider.Email,
				user: { name, email, password },
				access_code: accessCode
			},
			inviteHash,
			isGuestSignup
		);
	}

	private async createAccountWithExtProvider(
		{ provider, idToken } : ExternalProviderAuthParams,
		inviteHash?: string,
		isGuestSignup?: boolean
	) {
		return this.signUpRequestWithTracking(
			{ provider, user: { id_token: idToken } },
			inviteHash,
			isGuestSignup
		);
	}

	private async signUpRequestWithTracking(
		requestParams: SignUpEndpointParamsWithEmail | SignUpEndpointParamsWithExternalProvider,
		inviteHash?: string,
		isGuestSignup?: boolean
	) {
		const response = await this.signUpRequest( {
			...requestParams,
			invite_hash: inviteHash,
			skip_workspace_creation: isGuestSignup || undefined
		} );

		trackUserSignedUp( {
			isInvitationSignup: !!inviteHash,
			isGuestSignup: !!isGuestSignup
		} );

		return response;
	}

	private signUpRequest( params: SignUpEndpointParams ) {
		return this.api.post( `${USERS_URL}/sign_up`, params );
	}

	private throwUnprocessableCredentialsError( error: ResponseError ) {
		throw UnprocessableCredentialsError.fromResponseError( error );
	}

	private setAuthentication() {
		this.authenticationStore.clear();
		this.authenticationStore.set();
	}

	private setGuest() {
		this.authenticationStore.setGuest();
	}

	private unsetAuthentication = () => {
		this.authenticationStore.clear();
	}

	private handleLogoutSuccess = () => {
		this.unsetAuthentication();
		trackUserLoggedOut();
	}
}
