/* eslint-disable func-names */
/* eslint-disable no-extend-native */

import uniqBy from 'lodash/uniqBy';

Array.prototype.maxValue = function ( paramSelector = v => v ) {
	return this.length ? Math.max( ...this.map( paramSelector ) ) : undefined;
};

Array.prototype.minValue = function ( paramSelector = v => v ) {
	return this.length ? Math.min( ...this.map( paramSelector ) ) : undefined;
};

Array.prototype.accumulate = function ( paramSelector = v => v ) {
	return this.reduce(
		( accumulator, currentElement ) => accumulator + paramSelector( currentElement ),
		0
	);
};

Array.prototype.count = function ( predicate ) {
	return this.accumulate( elem => ( predicate( elem ) ? 1 : 0 ) );
};

Object.defineProperty( Array.prototype, 'first', {
	get: function first() {
		if ( this.length === 0 ) { throw new Error( 'Tried to get the first element of an empty array' ); }
		return this[ 0 ];
	}
} );

Object.defineProperty( Array.prototype, 'last', {
	get: function last() {
		if ( this.length === 0 ) { throw new Error( 'Tried to get the last element of an empty array' ); }
		return this[ this.length - 1 ];
	}
} );

Array.prototype.listAsString = function () {
	if ( this.length <= 1 ) { return this.join(); }
	const separatedByCommas = this.slice( 0, -1 ).join( ', ' );
	return `${separatedByCommas} and ${this.last}`;
};

Array.prototype.findAndReplace = function ( predicate, replaceFunction ) {
	const elemIndex = this.findIndex( predicate );
	if ( elemIndex === -1 ) return this;

	const newArr = [ ...this ];
	newArr.splice( elemIndex, 1, replaceFunction( this[ elemIndex ] ) );
	return newArr;
};

Array.prototype.uniq = function ( paramSelector = v => v ) {
	return uniqBy( this, paramSelector );
};

Array.prototype.compact = function () {
	return this.filter( value => value !== undefined && value !== null );
};

Array.prototype.findElementByID = function ( id ) {
	return this.find( element => element.id === id );
};

Array.prototype.findLast = Array.prototype.findLast || function ( matcher ) {
	return [ ...this ].reverse().find( matcher );
};

Array.prototype.partition = function ( predicate ) {
	return this.reduce(
		( partition, element ) => {
			( predicate( element ) ? partition[ 0 ] : partition[ 1 ] ).push( element );
			return partition;
		},
		[ [], [] ]
	);
};

Array.prototype.removeDuplicatesBy = function ( equalityPredicate ) {
	return this.reduce( ( result, element ) => {
		if ( result.some( prevElement => equalityPredicate( prevElement, element ) ) ) {
			return result;
		}
		return [ ...result, element ];
	}, [] );
}
