import { WORKSPACES_URL } from '../config/urls';
import {
	FETCH_WORKSPACE_MEMBERS,
	FETCH_WORKSPACE_MEMBERS_AND_INVITATIONS,
	INVITE_WORKSPACE_MEMBER,
	UPDATE_WORKSPACE_MEMBER_ROLE,
	DELETE_WORKSPACE_MEMBER,
	FETCH_WORKSPACE_MEMBER_COLLABORATORS
} from './types';
import { fetchWorkspaceInvitations } from './workspaceInvitations';
import { catchNotAllowedResponseWrapper } from '../lib/catchNotAllowedResponseWrapper';
import { trackWorkspaceInvite, trackWorkspaceRoleEdit } from '../services/tracker/trackers/workspaces';
import { trackAddOnBought } from '../services/tracker/trackers/addOns';

export const fetchWorkspaceMembers = workspaceID => ( dispatch, _, api ) => dispatch( {
	type: FETCH_WORKSPACE_MEMBERS,
	promise: catchNotAllowedResponseWrapper( api.get( `${WORKSPACES_URL}/${workspaceID}/members` ), dispatch, workspaceID )
		.then( response => response.response ),
	meta: { workspaceID }
} );

export const fetchWorkspaceMemberCollaborators = workspaceID => ( dispatch, _, api ) => dispatch( {
	type: FETCH_WORKSPACE_MEMBER_COLLABORATORS,
	promise: catchNotAllowedResponseWrapper( api.get( `${WORKSPACES_URL}/${workspaceID}/members/collaborators` ), dispatch, workspaceID )
		.then( response => response.response ),
	meta: { workspaceID }
} );

export const fetchWorkspaceMembersAndInvitations = workspaceID => dispatch => dispatch( {
	type: FETCH_WORKSPACE_MEMBERS_AND_INVITATIONS,
	promise: Promise.all( [
		dispatch( fetchWorkspaceMembers( workspaceID ) ),
		dispatch( fetchWorkspaceInvitations( workspaceID ) )
	] )
} );

export const inviteWorkspaceMember = (
	workspace, addOn, emails, role, message
) => ( dispatch, _, api ) => {
	const emailsPayload = emails.map( email => ( { email, role, message } ) );
	const workspaceID = workspace.id;
	const seatsToAdd = emailsPayload.length - workspace.remainingSeats;

	return dispatch( {
		type: INVITE_WORKSPACE_MEMBER,
		promise: api.post(
			`${WORKSPACES_URL}/${workspaceID}/members/invite`,
			{ invites: emailsPayload }
		).then( ( { response } ) => {
			trackWorkspaceInvite( { role, workspaceID } );

			if ( seatsToAdd > 0 ) {
				trackAddOnBought( {
					addOnType: addOn.type,
					includedAmount: seatsToAdd,
					plan: workspace.planName,
					totalAmount: addOn.costByPeriod( workspace ) * seatsToAdd,
					billingPeriod: workspace.subscriptionInterval
				} );
			}

			return response;
		} ),
		meta: { workspaceID }
	} );
};

export const updateWorkspaceMemberRole = ( workspaceID, memberID, roleID ) => (
	dispatch, _, api
) => {
	trackWorkspaceRoleEdit( roleID );

	return dispatch( {
		type: UPDATE_WORKSPACE_MEMBER_ROLE,
		promise: catchNotAllowedResponseWrapper( api.patch(
			`${WORKSPACES_URL}/${workspaceID}/members/${memberID}`,
			{ role: roleID }
		), dispatch, workspaceID )
			.then( () => ( { workspaceID, memberID, roleID } ) ),
		meta: { workspaceID }
	} );
};

export const deleteWorkspaceMember = ( workspaceID, memberID ) => (
	dispatch, _, api
) => dispatch( {
	type: DELETE_WORKSPACE_MEMBER,
	promise: catchNotAllowedResponseWrapper( api.delete(
		`${WORKSPACES_URL}/${workspaceID}/members/${memberID}` ), dispatch, workspaceID
	),
	meta: { memberID }
} );
