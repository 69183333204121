import AddOnPrice from './addOnPrice';

export default class AddOn {
	constructor( {
		id, includedAmount, type, prices = []
	} ) {
		this.id = id;
		this.includedAmount = includedAmount;
		this.type = type;
		this.prices = prices;

		this._validateType();
	}

	get monthlyPrice() {
		return this.prices?.find( price => !price.isAnnualPrice );
	}

	get annualPrice() {
		return this.prices?.find( price => price.isAnnualPrice );
	}

	get hasPrices() {
		return this.prices.length > 0;
	}

	static fromJSON( properties ) {
		return new AddOn( {
			id: properties.id,
			includedAmount: properties.included_amount,
			type: properties.type,
			prices: properties.prices.map( price => AddOnPrice.fromJSON( price ) )
		} );
	}

	static get storageType() {
		return 'StorageAddOn';
	}

	static get membersType() {
		return 'MembersAddOn';
	}

	static validateType( type ) {
		if ( ![ AddOn.membersType, AddOn.storageType, null ].includes( type ) ) {
			throw Error( 'Invalid AddOn type' );
		}
	}

	toJSON() {
		return {
			id: this.id,
			included_amount: this.includedAmount,
			type: this.type,
			prices: this.prices.map( price => price.toJSON() )
		};
	}

	get monthlyPriceCost() {
		return this.monthlyPrice.cost;
	}

	get annualPriceCost() {
		return this.annualPrice.cost;
	}

	_validateType() {
		AddOn.validateType( this.type );
	}

	costByPeriod( workspace ) {
		return workspace.hasYearlySubscriptionInterval ? this.annualPriceCost : this.monthlyPriceCost;
	}
}
