import Project from './project';
import Label from './label';

export const PROJECT_STATUS_HIDDEN = 'archived';
export const PROJECT_STATUS_ACTIVE = 'active';

export default class ProjectWithMetrics extends Project {
	/**
	 * @param {number} id Project id
	 * @param {boolean} isNewForCurrentUser
	 * @param {boolean} hasNewActivity
	 * @param {boolean} hasNewAssets
	 */
	constructor(
		id,
		name,
		description,
		clientName,
		primaryContact,
		coverImageURL,
		coverAssetID,
		status,
		shareHash,
		shareHashDownloadable,
		unresolvedCommentsCount,
		hasRushPriority,
		currentRoleId,
		startDate,
		dueDate,
		createdAt,
		updatedAt,
		workspace,
		lastActivityAt,
		isNewForCurrentUser,
		hasNewActivity,
		hasNewAssets,
		label,
		storageUsedInBytes,
		totalAssetsCount,
		lastModifier,
		owner,
		onboardingWasClosed
	) {
		super(
			id,
			name,
			description,
			clientName,
			primaryContact,
			coverImageURL,
			coverAssetID,
			status,
			shareHash,
			shareHashDownloadable,
			unresolvedCommentsCount,
			hasRushPriority,
			currentRoleId,
			startDate,
			dueDate,
			createdAt,
			updatedAt,
			workspace,
			lastActivityAt,
			[],
			label,
			storageUsedInBytes,
			totalAssetsCount,
			lastModifier,
			owner,
			onboardingWasClosed
		);

		this.isNewForCurrentUser = isNewForCurrentUser;
		this.hasNewActivity = hasNewActivity;
		this.hasNewAssets = hasNewAssets;
	}

	get gridItemStatusConfig() {
		const {
			hasNewActivity, hasNewAssets, isNewForCurrentUser, hasActiveWorkspace
		} = this;
		if ( !hasActiveWorkspace ) {
			return {
				bottomText: 'Suspended', bottomTextClassName: 'suspended', bottomTextIcon: 'error'
			};
		}
		if ( isNewForCurrentUser ) { return { bottomText: 'New', bottomTextClassName: 'new-project' }; }
		if ( hasNewActivity ) {
			return { bottomText: 'New Activity', bottomTextClassName: 'new-activity' };
		}
		if ( hasNewAssets ) {
			return { bottomText: 'New Assets', bottomTextClassName: 'new-activity' };
		}
		return {};
	}

	// Serialization
	static fromJSON( projectProperties, projectMetrics, workspace ) {
		return new ProjectWithMetrics(
			projectProperties.id,
			projectProperties.name,
			projectProperties.description,
			projectProperties.client_name,
			projectProperties.primary_contact,
			projectProperties.cover_url,
			projectProperties.cover_id,
			projectProperties.status,
			projectProperties.share_hash,
			projectProperties.share_hash_downloadable,
			projectProperties.unresolved_comments_count,
			projectProperties.rush_priority,
			projectProperties.current_user_role.type,
			projectProperties.start_date ? new Date( projectProperties.start_date ) : null,
			projectProperties.due_date ? new Date( projectProperties.due_date ) : null,
			new Date( projectProperties.created_at ),
			new Date( projectProperties.updated_at ),
			workspace,
			projectProperties.last_activity_at,
			projectMetrics.is_new_for_current_user,
			projectMetrics.has_new_activity,
			projectMetrics.has_new_assets,
			projectProperties.label && Label.fromJSON( projectProperties.label ),
			projectProperties.storage_used_in_bytes,
			projectProperties.total_assets_count,
			projectProperties.last_activity_by,
			projectProperties.owner,
			projectProperties.onboarding_was_close
		);
	}
}
