// eslint-disable-next-line import/no-unresolved
import { BrowserAgent } from '@newrelic/browser-agent/loaders/browser-agent'
import { NEW_RELIC_LICENSE_KEY, NEW_RELIC_APP_ID, NEW_RELIC_ENABLE_SESSION_REPLAY } from './config/global';

const agentEnabled = !!NEW_RELIC_LICENSE_KEY;

const options = {
	init: { distributed_tracing: { enabled: true }, privacy: { cookies_enabled: true }, ajax: { deny_list: [ 'bam.nr-data.net' ] } },
	info: { beacon: 'bam.nr-data.net', errorBeacon: 'bam.nr-data.net', licenseKey: NEW_RELIC_LICENSE_KEY, applicationID: NEW_RELIC_APP_ID, sa: 1 },
	loader_config: { accountID: '4223615', trustKey: '4223615', agentID: NEW_RELIC_APP_ID, licenseKey: NEW_RELIC_LICENSE_KEY, applicationID: NEW_RELIC_APP_ID }
}

if ( NEW_RELIC_ENABLE_SESSION_REPLAY ) {
	options.init.session_replay = {
		enabled: true,
		block_selector: '',
		mask_text_selector: '*',
		sampling_rate: 10.0,
		error_sampling_rate: 100.0,
		mask_all_inputs: true,
		collect_fonts: true,
		inline_images: false,
		inline_stylesheet: true,
		mask_input_options: {}
	};
}

const agent = agentEnabled
	? new BrowserAgent( options )
	: null;

export default agent;
