import { InviteWorkspaceMemberStrategy } from './InviteWorkspaceMemberStrategy';

export default class InviteMultipleWorkspaceMemberStrategy extends InviteWorkspaceMemberStrategy {
	protected renderSuccessMessageWithEnoughSpace() {
		this.showSuccessMessage( {
			title: this.successMessageTitle,
			description: `You've successfully invited ${this.membersToAdd} new members to your workspace.`
		} );
	}

	protected renderSuccessMessageWithoutEnoughSpace() {
		const seatsMessage = this.seatsToAdd > 1 ? `${this.seatsToAdd} seats` : '1 seat';

		this.showSuccessMessage( {
			title: this.successMessageTitle,
			description: `You've successfully added ${seatsMessage} and invited ${this.membersToAdd} new members to your workspace.`
		} );
	}

	protected renderAlreadyInvitedErrorMessage() {
		this.showErrorMessage( {
			title: this.errorMessageTitle,
			description: 'At least one email you’ve entered has already been invited.'
		} );
	}

	protected renderDefaultErrorMessage() {
		this.showErrorMessage( {
			title: this.errorMessageTitle,
			description: 'An error occurred while inviting members. Please try again later.'
		} );
	}
}
