import type Asset from '../asset';
import { FilterJSON } from './types';
import FilterOption from './filterOption';

export default class LabelFilter implements FilterOption {
	labelId: number | null;

	constructor( labelId: number | null ) {
		this.labelId = labelId;
	}

	get id() {
		return `label-${this.labelId}`;
	}

	isPassedBy( asset: Asset ) {
		return ( asset.label?.id || null ) === this.labelId;
	}

	toJson() {
		return { label: this.labelId }
	}

	static fromJson( json: FilterJSON ) {
		if ( typeof json.label !== 'number' && json.label !== null ) {
			return null;
		}

		return new LabelFilter( json.label );
	}
}
