import { Dispatch } from 'redux';
import { UserJson } from '../models/user/user';
import { AssetJSON } from '../modules/projectView/models/asset';
import { FolderJSON, OrganizerType } from '../modules/projectView/models/folder';

export const UPDATER_ACTION_TYPE = {
	currentUser: '__UPDATE_CURRENT_USER',
	assets: '__UPDATE_ASSETS',
	folders: '__UPDATE_FOLDERS',
	workspaceOnboardingMarkedAsClosed: '__MARK_WORKSPACE_ONBOARDING_AS_CLOSED'
} as const

/*
* This class is meant to update the redux store when a change with react query is made.
*/

export default class ReduxStoreUpdater {
	dispatch: Dispatch;

	constructor( dispatch: Dispatch ) {
		this.dispatch = dispatch;
	}

	updateCurrentUser( user: UserJson ) {
		this.dispatch( { type: UPDATER_ACTION_TYPE.currentUser, payload: { user } } );
	}

	updateAssets( assets: AssetJSON[], { projectID }: { projectID: number } ) {
		this.dispatch( {
			type: UPDATER_ACTION_TYPE.assets,
			payload: assets,
			meta: { projectID }
		} );
	}

	updateFolders(
		folders: FolderJSON[],
		{ organizerType, organizerID }: { organizerType: OrganizerType, organizerID: number }
	) {
		this.dispatch( {
			type: UPDATER_ACTION_TYPE.folders,
			payload: folders,
			meta: { organizerType, organizerID }
		} );
	}

	markWorkspaceOnboardingAsClosed( workspaceID: number ) {
		this.dispatch( {
			type: UPDATER_ACTION_TYPE.workspaceOnboardingMarkedAsClosed,
			meta: { workspaceID }
		} );
	}
}
