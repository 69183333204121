import { makeAutoObservable } from 'mobx';
import { FilterJSON, FilterType, AssetFilterMandatoryParams } from '../modules/projectView/models/filtersAndSorts/types';
import FilterOption from '../modules/projectView/models/filtersAndSorts/filterOption';
import { trackChangedAssetsFilter } from '../../services/tracker/trackers/assets';

type FilterSerialization = {
	type: FilterType,
	searchText: string,
	filterOptions: FilterJSON[]
}

export type FilterStoreSerialization = {
	[projectID: number]: FilterSerialization
}

export type FilterState = {
	[projectID: number]: AssetFilterMandatoryParams;
}

export default class FilterStore {
	filterState: FilterState;
	private NEW_CONFIGURATION_TEMPLATE = {
		type: 'image' as FilterType,
		searchText: '',
		filterOptions: [] as FilterOption[]
	};

	constructor( filterState: FilterState = {} ) {
		this.filterState = filterState;

		makeAutoObservable( this );
	}

	serialize(): FilterStoreSerialization {
		const serialization: FilterStoreSerialization = {};

		Object.entries( this.filterState ).forEach(
			( [ projectID, filterParams ] : [ string, AssetFilterMandatoryParams ] ) => {
				serialization[ Number( projectID ) ] = {
					type: filterParams.type,
					searchText: filterParams.searchText,
					filterOptions: filterParams.filterOptions.map( option => option.toJson() )
				};
			}
		);

		return serialization;
	}

	hydrate( serialization: FilterStoreSerialization ) {
		Object.entries( serialization ).forEach( ( [ projectID, filterParams ] : [ string, FilterSerialization ] ) => {
			this.filterState[ Number( projectID ) ] = {
				type: filterParams.type,
				searchText: filterParams.searchText,
				filterOptions: filterParams.filterOptions
					.map( option => FilterOption.fromJson( option ) )
					.filter( ( option ): option is FilterOption => option !== null )
			};
		} );
	}

	clear() {
		this.filterState = {};
	}

	addProjectFilterNewConfiguration( projectID: number ) {
		this.filterState[ projectID ] = this.NEW_CONFIGURATION_TEMPLATE;

		return this.filterState[ projectID ];
	}

	projectFilterConfigurationIsSet( projectID: number ) {
		return !!this.filterState[ projectID ];
	}

	getProjectFilterConfiguration( projectID: number ) {
		if ( this.projectFilterConfigurationIsSet( projectID ) ) {
			return this.filterState[ projectID ];
		}

		return this.addProjectFilterNewConfiguration( projectID );
	}

	setProjectFilterType( projectID: number, type: FilterType ) {
		this.filterState[ projectID ].type = type;
	}

	setProjectFilterSearchText( projectID: number, searchText: string ) {
		this.filterState[ projectID ].searchText = searchText;
	}

	setProjectFilterOptions( projectID: number, filterOptions: FilterOption[] ) {
		trackChangedAssetsFilter( projectID, filterOptions.map( option => option.id ) );
		this.filterState[ projectID ].filterOptions = filterOptions;
	}
}
