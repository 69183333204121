import { shallowClone } from '../lib/objectUtils';
import ProjectRole from './projectRole';

export default class FilterOption {
	constructor( id, name, filterCondition ) {
		this.id = id;
		this.name = name;
		this.filterCondition = filterCondition;
	}

	get filter() { return this.id; }

	get label() { return this.name; }

	get value() { return this.id; }

	get isLabelOption() { return false; }

	get isFiltering() {
		return this.filterCondition !== undefined;
	}

	static fromJSON( { id, name, filterCondition } ) {
		return new FilterOption(
			id,
			name,
			filterCondition
		);
	}

	toJSON() {
		return {
			id: this.id,
			name: this.name,
			filterCondition: this.filterCondition
		};
	}
}

FilterOption.none = new FilterOption(
	'none',
	'',
	undefined
);

FilterOption.resetFilter = Object.assign(
	shallowClone( FilterOption.none ),
	{ name: 'Reset Filter', greyOut: true }
);

FilterOption.byUnresolvedComments = new FilterOption(
	'unresolved_comments',
	'Unresolved Comments',
	{ field: 'hasUnresolvedComments', value: true, type: 'equal' }
);

FilterOption.byNewAssets = new FilterOption(
	'by_new_assets',
	'New assets',
	{ field: 'hasNewAssets', value: true, type: 'equal' }
);

FilterOption.byPriority = new FilterOption(
	'priority',
	'Priority',
	{ field: 'hasRushPriority', value: true, type: 'equal' }
);

const createFilterOptionByProjectRole = projectRole => new FilterOption(
	`by_${projectRole.id}_project_role`,
	`I'm${projectRole.isOwner ? '' : ' a'} ${projectRole.name}`,
	{ field: 'currentRoleId', value: projectRole.id, type: 'equal' }
);

FilterOption.byOwnerProjectRole = createFilterOptionByProjectRole( ProjectRole.owner() );

// eslint-disable-next-line max-len
FilterOption.byAdminProjectRole = createFilterOptionByProjectRole( ProjectRole.projectCollaborator() );

FilterOption.byGuestProjectRole = createFilterOptionByProjectRole( ProjectRole.projectGuest() );

FilterOption.byApproved = new FilterOption(
	'approved',
	'Approved',
	{ field: 'isApproved', value: true, type: 'equal' }
);

FilterOption.byApprovalPending = new FilterOption(
	'not_approved',
	'Not Approved',
	{ field: 'isApproved', value: false, type: 'equal' }
);

FilterOption.byComments = new FilterOption(
	'comments',
	'Comments',
	{ field: 'hasComments', value: true, type: 'equal' }
);

FilterOption.byMentionType = new FilterOption(
	'mentions',
	'@ Mentions',
	{ field: 'type', value: 'mention', type: 'equal' }
);

FilterOption.byCommentType = new FilterOption(
	'comments',
	'Comments',
	{ field: 'type', value: 'new_root_comment', type: 'equal' }
);

FilterOption.byReplyType = new FilterOption(
	'replies',
	'Replies',
	{ field: 'type', value: 'new_reply_comment', type: 'equal' }
);

FilterOption.byUploadedAssetType = new FilterOption(
	'uploadedAssets',
	'Uploaded Assets',
	{ field: 'type', value: 'asset_uploaded', type: 'equal' }
);

FilterOption.byFlaggedAssetsType = new FilterOption(
	'flaggedAssets',
	'Flagged',
	{ field: 'type', value: 'set_rush_priority', type: 'equal' }
);

FilterOption.byAssetsApprovedType = new FilterOption(
	'assetsApproved',
	'Approved Assets',
	{ field: 'type', value: 'asset_approved', type: 'equal' }
);

FilterOption.byNotMarkAsCompleted = new FilterOption(
	'unresolvedComments',
	'Unresolved Comments',
	{ field: 'wasMarkedAsCompleted', value: false, type: 'equal' }
);

FilterOption.byUserAdded = new FilterOption(
	'userAdded',
	'Users Added',
	{ field: 'type', value: 'new_user', type: 'equal' }
);

FilterOption.byRoundAdded = new FilterOption(
	'roundAdded',
	'Rounds Added',
	{ field: 'type', value: 'new_round', type: 'equal' }
);

FilterOption.byGuestView = new FilterOption(
	'guestView',
	'Guest Views',
	{ field: 'type', value: 'new_guest_view', type: 'equal' }
);

FilterOption.byAllComments = new FilterOption(
	'allComments',
	'All Comments',
	undefined
);

FilterOption.all = Object.values( FilterOption );

FilterOption.projectsFiltersByID = {
	[ FilterOption.none.id ]: FilterOption.none,
	[ FilterOption.byPriority.id ]: FilterOption.byPriority,
	[ FilterOption.byNewAssets.id ]: FilterOption.byNewAssets,
	[ FilterOption.byUnresolvedComments.id ]: FilterOption.byUnresolvedComments,
	[ FilterOption.byOwnerProjectRole.id ]: FilterOption.byOwnerProjectRole,
	[ FilterOption.byAdminProjectRole.id ]: FilterOption.byAdminProjectRole,
	[ FilterOption.byGuestProjectRole.id ]: FilterOption.byGuestProjectRole
};

FilterOption.notificationsFiltersByID = {
	[ FilterOption.none.id ]: FilterOption.none,
	[ FilterOption.byMentionType.id ]: FilterOption.byMentionType,
	[ FilterOption.byCommentType.id ]: FilterOption.byCommentType,
	[ FilterOption.byReplyType.id ]: FilterOption.byReplyType,
	[ FilterOption.byUploadedAssetType.id ]: FilterOption.byUploadedAssetType,
	[ FilterOption.byFlaggedAssetsType.id ]: FilterOption.byFlaggedAssetsType,
	[ FilterOption.byAssetsApprovedType.id ]: FilterOption.byAssetsApprovedType
};

FilterOption.commentsFiltersByID = {
	[ FilterOption.byNotMarkAsCompleted.id ]: FilterOption.byNotMarkAsCompleted,
	[ FilterOption.byAllComments.id ]: FilterOption.byAllComments
};

FilterOption.projectsFilters = [
	FilterOption.resetFilter,
	FilterOption.byPriority,
	FilterOption.byNewAssets,
	FilterOption.byUnresolvedComments,
	{
		label: 'My role in the project',
		options: [
			FilterOption.byOwnerProjectRole,
			FilterOption.byAdminProjectRole,
			FilterOption.byGuestProjectRole
		]
	}
];

FilterOption.notificationsFilters = [
	FilterOption.resetFilter,
	FilterOption.byMentionType,
	FilterOption.byCommentType,
	FilterOption.byReplyType,
	FilterOption.byUploadedAssetType,
	FilterOption.byFlaggedAssetsType,
	FilterOption.byAssetsApprovedType,
	FilterOption.byUserAdded,
	FilterOption.byRoundAdded,
	FilterOption.byGuestView
];

FilterOption.commentsFilters = [
	FilterOption.byNotMarkAsCompleted,
	FilterOption.byAllComments
];
