import { EventHandler, SyntheticEvent } from 'react';

type EventMethod = 'preventDefault' | 'stopPropagation'

type KonvaEvent = { evt: Event }

const callMethodOnEvent = <E extends SyntheticEvent | KonvaEvent>( method: EventMethod ) => ( event: E ) => {
	const e = ( event as KonvaEvent ).evt ?? event;
	if ( !e ) return;
	e[ method ]?.();
};

const callMethodOnEventAndCall = <E extends SyntheticEvent>( method: EventMethod ) => (
	( callback: ( e: E ) => void ) : EventHandler<E> => ( event: E ) => {
		callMethodOnEvent( method )( event );
		callback( event );
	}
)

export const preventDefault = callMethodOnEvent( 'preventDefault' );
export const stopPropagation = callMethodOnEvent( 'stopPropagation' );

export const preventDefaultAndCall = callMethodOnEventAndCall( 'preventDefault' );
export const stopPropagationAndCall = callMethodOnEventAndCall( 'stopPropagation' );

export const combineHandlers = <TEvent>(
	handler1?: ( event: TEvent ) => void,
	handler2?: ( event: TEvent ) => void
) => ( event: TEvent ) => {
		handler1?.( event );
		handler2?.( event );
	}
