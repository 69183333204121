import { sharedTracker } from '../Tracker';
import events from '../events';

export const trackAssetDeletion = assetCount => sharedTracker
	.trackAssetAction( {
		action: events.assets.ASSET_DELETED,
		label: assetCount.toString()
	} );

export const trackCompareRoundsOpened = () => sharedTracker
	.trackImageToolAction( {
		action: events.imageTools.COMPARE_ROUNDS_OPENED
	} );

export const trackAssetImageZoomed = asset => sharedTracker
	.trackImageToolAction( {
		action: events.imageTools.ASSET_IMAGE_ZOOMED,
		label: asset && asset.name
	} );

export const trackDownloadedAnAsset = asset => sharedTracker
	.trackAssetAction( {
		action: events.assets.ASSET_DOWNLOADED,
		label: asset && asset.name
	} );

export const trackDownloadedMultipleAssets = ( {
	projectID, assetsCount, foldersCount, withRoundNumber, lowRes
} ) => sharedTracker
	.trackAssetAction( {
		action: events.assets.ASSETS_DOWNLOADED,
		project_id: projectID,
		assets_count: assetsCount,
		folders_count: foldersCount,
		with_round_number: withRoundNumber,
		low_res: lowRes
	} );

export const trackChangedAssetsSort = sortOption => sharedTracker
	.trackAssetAction( {
		action: events.assets.ASSETS_SORTED,
		criteria: sortOption.name
	} );

export const trackChangedAssetsFilter = ( projectID, filters ) => sharedTracker
	.trackAssetAction( {
		action: events.assets.ASSETS_FILTERED,
		criteria: filters,
		project_id: projectID
	} );

export const trackCustomSortedAssets = () => sharedTracker
	.trackAssetAction( {
		action: events.assets.ASSETS_CUSTOM_SORTED
	} );

export const trackSearchedAssets = ( filterText ) => {
	if ( !filterText || filterText.length === 0 ) { return; }

	sharedTracker.trackAssetAction( {
		action: events.assets.ASSETS_SEARCHED,
		label: filterText
	} );
};

export const trackMovedToAPreviousRound = asset => sharedTracker
	.trackImageToolAction( {
		action: events.imageTools.MOVED_TO_PREVIOUS_ROUND,
		label: asset && asset.name
	} );

export const trackAssetsUploaded = ( { assetsCount, assetsFileTypesCount } ) => sharedTracker
	.trackAssetAction( {
		action: events.assets.ASSETS_UPLOADED,
		assets_count: assetsCount,
		...assetsFileTypesCount
	} );

export const trackAssetApprovedFromAssetView = ( assetCount ) => {
	sharedTracker.trackAssetAction( {
		action: events.assets.ASSET_APPROVED_ASSET_VIEW,
		label: assetCount.toString()
	} );
};

export const trackAssetDissapprovedFromAssetView = ( assetCount ) => {
	sharedTracker.trackAssetAction( {
		action: events.assets.ASSET_DISAPPROVED_ASSET_VIEW,
		label: assetCount.toString()
	} );
};

export const trackAssetApprovedFromMarkupView = () => {
	sharedTracker.trackAssetAction( {
		action: events.assets.ASSET_APPROVED_MARKUP_VIEW
	} );
};

export const trackAssetDissapprovedFromMarkupView = () => {
	sharedTracker.trackAssetAction( {
		action: events.assets.ASSET_DISAPPROVED_MARKUP_VIEW
	} );
};
