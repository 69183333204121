import { makeAutoObservable } from 'mobx';
import AsyncActionPresenterFactory from '../../factories/AsyncActionPresenterFactory';
import SnackbarFactory from '../../factories/SnackbarFactory';
import PopupFactory from '../../factories/PopupFactory';
import { isMembersAddOnInUseError } from '../../errors/addons';

export default class MembersAddOnModalPresenter {
	constructor( modalPresenter ) {
		this._modalPresenter = modalPresenter;

		makeAutoObservable( this );
	}

	get title() {
		return '+1 Workspace Seat';
	}

	get text() {
		return 'Add an extra seat for a workspace member to your current plan. Add as many as you need.';
	}

	get buttonText() {
		return 'Add 1 Workspace Seat';
	}

	get removeButtonText() {
		return 'Remove 1 Workspace Seat';
	}

	get quantityText() {
		return `You currently have ${this.addOnQuantity} extra ${'seat'.pluralize( this.addOnQuantity )}`;
	}

	get addOnQuantity() {
		return this._workspace.membersAddOnQuantity;
	}

	buyButtonWasClicked = () => {
		AsyncActionPresenterFactory.forBuyMembersAddOn( {
			addOn: this._modalPresenter.addOn,
			addOnPrice: this._modalPresenter.addOnPrice,
			addOnSystem: this._modalPresenter.addOnSystem,
			popupSystem: this._modalPresenter.popupSystem,
			workspace: this._workspace,
			snackbarSystem: this._modalPresenter.snackbarSystem,
			description: this._popupDescription
		} ).trigger().then( ( result ) => {
			if ( result?.error ) return;
			this._modalPresenter.close();
		} );
	};

	removeButtonWasClicked = () => {
		AsyncActionPresenterFactory.forRemoveMembersAddOn( {
			addOnPrice: this._modalPresenter.addOnPrice,
			addOnSystem: this._modalPresenter.addOnSystem,
			popupSystem: this._modalPresenter.popupSystem,
			workspaceID: this._workspace.id,
			snackbarSystem: this._modalPresenter.snackbarSystem,
			onError: this._onRemoveAddOnError,
			description: this._confirmationPopupRemovalDescription
		} ).trigger().then( ( result ) => {
			if ( result?.error ) return;
			this._modalPresenter.close();
		} );
	};

	_onRemoveAddOnError = ( result ) => {
		if ( !result?.error ) return undefined;
		if ( isMembersAddOnInUseError( result.payload ) ) {
			return PopupFactory.removeMembersAddOnErrorPopup( {
				popupSystem: this._modalPresenter.popupSystem
			} ).then( ( confirm ) => {
				if ( confirm ) { this._redirectToMembersTab(); }
			} );
		}
		return SnackbarFactory.defaultErrorMessage( this._modalPresenter.snackbarSystem );
	}

	_redirectToMembersTab() {
		this._modalPresenter.history.replace( `/user/workspaces/${this._workspace.id}/members` );
	}

	get _hasYearlySubscriptionInterval() {
		return this._workspace.hasYearlySubscriptionInterval;
	}

	get _confirmationPopupDescriptionPeriod() {
		return this._hasYearlySubscriptionInterval ? 'year' : 'month';
	}

	get _confirmationPopupRemovalDescription() {
		return `Are you sure you want to remove a workspace seat? You still have ${this._workspace.timeLeftUntilBillingPeriodEnd} to use it and if you proceed you will lose it.`;
	}

	get _popupDescription() {
		return this._hasYearlySubscriptionInterval
			? this._annualPopUpDescription
			: this._monthlyPopUpDescription;
	}

	get _monthlyPopUpDescription() {
		return `You will add ${this._includedAmount} more seat to your plan for a total of ${this._totalSeatsWithAddOn} for an extra $${this._modalPresenter.cost} per month billed with your subscription.`;
	}

	get _annualPopUpDescription() {
		return `You will add ${this._includedAmount} more seat to your plan for a total of ${this._totalSeatsWithAddOn} seats at a prorated $${this._proratedCost} ($${this._modalPresenter.cost} per year billed with your subscription).`;
	}

	get _includedAmount() {
		return this._modalPresenter.addOnIncludedAmount;
	}

	get _totalSeatsWithAddOn() {
		return this._workspace.includedMembers + this._modalPresenter.addOnIncludedAmount;
	}

	get _proratedCost() {
		return this._workspace.prorateCostToEndOfBillingPeriod( this._modalPresenter.cost );
	}

	get _workspace() {
		return this._modalPresenter.workspace;
	}
}
