import { makeAutoObservable } from 'mobx';
import AuthenticationStore from './AuthenticationStore';
import { RootStoreSerialization } from './types';
import FilterStore, { FilterState } from './FilterStore';

export type PersistableRootStore = {
	serializationToPersist: () => object;
	rehydrateWithSerialization: ( serialization: object ) => void;
};

type RootStoreParams = {
	isAuthenticated?: boolean;
	filterState?: FilterState;
};

export class RootStore implements PersistableRootStore {
	authenticationStore: AuthenticationStore;
	filterStore: FilterStore;

	constructor( { isAuthenticated, filterState }: RootStoreParams = {} ) {
		this.authenticationStore = new AuthenticationStore( isAuthenticated );
		this.filterStore = new FilterStore( filterState );

		makeAutoObservable( this );
	}

	get isAuthenticated() {
		return this.authenticationStore.isAuthenticated;
	}

	serializationToPersist(): RootStoreSerialization {
		return {
			authenticationStore: this.authenticationStore.serialize(),
			filterStore: this.filterStore.serialize()
		};
	}

	rehydrateWithSerialization( serialization: RootStoreSerialization ) {
		if ( serialization.authenticationStore ) {
			this.authenticationStore.hydrate( serialization.authenticationStore );
		}

		if ( serialization.filterStore ) {
			this.filterStore.hydrate( serialization.filterStore );
		}
	}

	clearStore() {
		this.authenticationStore.clear();
		this.filterStore.clear();
	}
}
